@font-face {
  font-family: Manrope;
  src: url("Manrope-Regular.54931e47.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-Bold.deaac784.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-ExtraBold.c14ad99a.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-ExtraLight.4bb712df.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-Light.5946b79a.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-Medium.e000253d.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-SemiBold.15e97dbe.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
/*# sourceMappingURL=about.b9d00736.css.map */
